import { Route, Redirect } from 'react-router-dom'

import { AUTH_ROUTES } from '@core/constants/coreRoutes'
import AppLayout from '@core/containers/AppLayout'
import { useAppSelector } from '@core/redux/store'
import { isAuthRoutes } from '@core/services/auth.service'
import {LocationState} from "@core/types/Location";

const PrivateRoute: any = ({ component, ...rest }: { component: any, restricted: boolean }) => {
  const Component = component
  const { cognitoSession } = useAppSelector((state: any) => state.auth)
  const isAuthenticated = cognitoSession && cognitoSession.idToken ? true : false
  return (
    <Route
      { ...rest }
      render={ ({ location }) => {
        if (!isAuthRoutes(location.pathname) && isAuthenticated) {
            const from = (location.state as LocationState)?.from
            if (from) {
                return <Redirect to={{ pathname: from }} />
            }
            return (<AppLayout><Component { ...location } /></AppLayout>)
        }
          return (
              <Redirect
                  to={{
                      pathname: AUTH_ROUTES.LOGIN,
                      state: { from: location.pathname }
                  }}
              />
          )} }
    />
  )
}

export default PrivateRoute
